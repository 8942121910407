import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {

    getPlaylists() {
        return axios.get(`${api_root}/v1/playlists`)
    },

    getPlaylist(id) {
        return axios.get(`${api_root}/v1/playlists/${id}`)
    },

    deletePlaylist(id) {
        return axios.delete(`${api_root}/v1/playlists/${id}`)
    },

    createPlaylist(name, user_id, url, provider_id, file) {
        let formData = new FormData();
        if (file) {
            formData.append('playlist[file]', file);
        }
        formData.append('playlist[name]', name);
        formData.append('playlist[user_id]', user_id);
        formData.append('playlist[url]', url);
        formData.append('playlist[provider_id]', provider_id);

        return axios(`${api_root}/v1/playlists`, {
            method: "post",
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

}