<template>
  <div class="">
    <CRow class="">
      <CCol md="8">
        <CCard>
          <CCardBody class="p-4">
            <CForm @submit="createPlaylist">
              <h1>New Playlist</h1>
              <p class="text-muted">Create your new playlist</p>

              <CAlert
                color="danger"
                v-for="(error, index) in errors"
                :key="index"
                >{{ error }}</CAlert
              >

              <CInput
                label="Playlist Name"
                placeholder="My Supa-Dupa Playlist"
                v-model="name"
              />
              <CInput
                label="Playlist Url"
                placeholder="https://..."
                v-model="url"
              />
              <CSelect
                @update:value="onProviderChange"
                label="Provider"
                :options="providersSelectOptions"
              />

              <p class="mb-2">Playlist File</p>

              <vue-dropzone
                ref="dropzone"
                id="dropzone"
                class="border"
                @vdropzone-file-added="vfileAdded"
                @vdropzone-removed-file="vremoved"
                @vdropzone-success="vsuccess"
                :options="dropzoneOptions"
              >
              </vue-dropzone>
              <p class="text-muted small mt-1 mb-3">
                Only one file is allowed.
              </p>

              <CSpinner color="info" v-if="loading" />

              <CButton color="success" v-if="!loading" type="submit"
                >Create</CButton
              >
              <CButton color="link" type="button" to="/playlists"
                >Back to list</CButton
              >
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import playlists from "@/api/playlists";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  data() {
    return {
      errors: [],
      name: null,
      url: null,
      provider_id: null,
      loading: false,
      providersWaiting: true,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        addRemoveLinks: true,
        maxFiles: 1,
        autoQueue: false,
        autoProcessQueue: false,
      },
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      ...mapGetters({
        authStatus: "auth/authStatus",
        isLoggedIn: "auth/isLoggedIn",
        providers: "providers/providers",
      }),
    }),
    providersSelectOptions: function () {
      return this.providers.map((p) => ({ value: p.id, label: p.name }));
    },
  },
  watch: {
    providers: function (val) {
      if (val && val.length > 0 && this.providersWaiting) {
        this.provider_id = val[0].id;
        this.providersWaiting = false;
      }
    },
  },

  methods: {
    onProviderChange(val) {
      this.provider_id = val;
    },
    vfileAdded() {
      if (this.$refs.dropzone.dropzone.files.length > 1) {
        this.$refs.dropzone.removeFile(this.$refs.dropzone.dropzone.files[0]);
      }
    },
    vremoved() {},
    vsuccess(file) {
      this.$refs.dropzone.dropzone.files[0] = file;
    },
    createPlaylist(e) {
      this.loading = true;
      this.errors = [];

      if (!this.name) {
        this.loading = false;
        this.errors.push("Name can't be blank");
      }

      if (!this.provider_id) {
        this.loading = false;
        this.errors.push("Provider can't be blank");
      }

      if (!this.url && this.$refs.dropzone.dropzone.files.length == 0) {
        this.loading = false;
        this.errors.push("Playlist url or file can't be blank");
      }

      let file = this.$refs.dropzone.dropzone.files[0];

      if (!this.errors.length) {
        playlists
          .createPlaylist(
            this.name,
            this.user.id,
            this.url,
            this.provider_id,
            file
          )
          .then(() => {
            this.loading = false;
            this.$router.push({ path: "/playlists" });
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors;
            }
            this.loading = false;
          });
      }

      e.preventDefault();
    },
  },
  mounted() {
    this.$store.dispatch("providers/getProviders");
  },
};
</script>

<style lang="scss" >
.dz-progress {
  display: none !important;
}
</style>
